import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import FileIcon from '../atoms/graphics/file-icon'
import CalendarIcon from '../atoms/graphics/calendar-icon'
import ExternalLinkIcon from '../atoms/graphics/external-link-icon'
import YoutubeIcon from '../atoms/graphics/youtube-icon'
import PhoneIcon from '../atoms/graphics/phone-icon'
import PBTArrowIcon from '../atoms/graphics/pbt-arrow-icon'

interface Resource {
  title: string
  resourceType: string
  resourceUrl?: string
  resourceDocument?: {
    file: {
      url: string
    }
  }
}

interface Subcategory {
  header: string
  resourceLinks: Array<Resource>
}

interface Props {
  subcategory: Array<Subcategory>
}

interface DataProp {
  title: string
  url: string
  icon: (StyleProps) => JSX.Element
}
interface ResourceProps {
  data: DataProp
}

interface StyleProps {
  colour?: string
  height?: string
}

const SubcategoryContainer = styled.div`
  height: ${(props: StyleProps): string => props.height};
  margin: 4rem 0;
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    height: auto;
    margin: 2rem 0;
  }
`

const Subcategory = styled.div`
  width: 49%;
  min-width: 56rem;
  padding: 3rem 5rem;
  margin-bottom: 2.5rem;
  border: 1px solid ${theme.colours.gray1};
  &:nth-child(2n + 1) {
    order: 1;
  }
  &:nth-child(2n) {
    order: 2;
  }
  &:hover {
    background-color: ${theme.colours.gray4};
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    min-width: 100%;
    padding: 3rem 2rem;
    &:nth-child(2n) {
      order: 1;
    }
  }
`

const SubcategoryHeader = styled.h3`
  color: ${theme.colours.secondary};
  ${Subcategory}:hover & {
    color: ${theme.colours.quaternary};
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 1.9rem;
  }
`

const StyledLink = styled.a`
  display: flex;
  align-items: flex-start;
  padding-bottom: 0.5rem;
  &:hover {
    color: ${theme.colours.quaternary};
  }
  span {
    padding-left: 0.5rem;
    padding-top: 0.3rem;
  }

  @media only screen and ${theme.breakpoints.toLargeScreen} {
    align-items: flex-start;
    font-size: 1.6rem;
  }
`

const IconContainer = styled.div`
  display: inline-flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    svg {
      height: 2rem;
    }
  }
`

const ResourceLink = (props: ResourceProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false)
  const Icon = props.data.icon

  return (
    <StyledLink
      target="_blank"
      href={props.data.url}
      onMouseEnter={(): void => setIsHovered(true)}
      onMouseLeave={(): void => setIsHovered(false)}
    >
      <IconContainer>
        <Icon
          colour={isHovered ? theme.colours.quaternary : theme.colours.black}
        />
      </IconContainer>
      <span>{props.data.title}</span>
    </StyledLink>
  )
}

const ResourcesSubcategory = (props: Props): JSX.Element => {
  const resourceLink = (data: Resource): DataProp => {
    switch (data?.resourceType?.toLowerCase()) {
      case 'document':
        return {
          title: data.title,
          url: data.resourceDocument
            ? data.resourceDocument.file.url
            : data.resourceUrl
            ? data.resourceUrl
            : '#',
          icon: FileIcon,
        }
      case 'event':
        return {
          title: data.title,
          url: data.resourceUrl ? data.resourceUrl : '#',
          icon: CalendarIcon,
        }
      case 'video':
        return {
          title: data.title,
          url: data.resourceUrl ? data.resourceUrl : '#',
          icon: YoutubeIcon,
        }
      case 'contact':
        return {
          title: data.title,
          url: data.resourceUrl ? data.resourceUrl : '#',
          icon: PhoneIcon,
        }
      case 'internal website':
        return {
          title: data.title,
          url: data.resourceUrl ? data.resourceUrl : '#',
          icon: PBTArrowIcon,
        }
      default:
        return {
          title: data.title,
          url: data.resourceUrl ? data.resourceUrl : '#',
          icon: ExternalLinkIcon,
        }
    }
  }
  const getContainerHeight = (): string => {
    let count = 0

    props?.subcategory?.forEach(item => {
      item?.resourceLinks?.forEach(() => {
        count++
      })
    })
    if (props?.subcategory?.length < 2) {
      return props.subcategory.length * 14 + count * 4 + 'rem'
    }
    return props?.subcategory?.length > 0
      ? ((props.subcategory.length * 14 + count * 4) / 100) * 60 + 30 + 'rem'
      : '0rem'
  }

  return (
    <SubcategoryContainer height={getContainerHeight()}>
      {props?.subcategory && props?.subcategory?.length > 0
        ? props?.subcategory?.map((subcategory, index) => {
            return (
              <Subcategory key={index}>
                <SubcategoryHeader>{subcategory.header}</SubcategoryHeader>
                {subcategory?.resourceLinks?.map((resource, index) => {
                  return (
                    <ResourceLink data={resourceLink(resource)} key={index} />
                  )
                })}
              </Subcategory>
            )
          })
        : null}
    </SubcategoryContainer>
  )
}

export default ResourcesSubcategory

import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const ExternalLinkIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.colour ? props.colour : theme.colours.black}
        fillRule="evenodd"
        d="M19 13v6c0 1.7-1.3 3-3 3H5c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h6c.6 0 1 .4 1 1s-.4 1-1 1H5c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1h11c.6 0 1-.4 1-1v-6c0-.6.4-1 1-1s1 .4 1 1zm2.9-10.4c-.1-.2-.3-.4-.5-.5-.1-.1-.3-.1-.4-.1h-6c-.6 0-1 .4-1 1s.4 1 1 1h3.6l-9.3 9.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L20 5.4V9c0 .6.4 1 1 1s1-.4 1-1V3c0-.1 0-.3-.1-.4z"
      />
    </svg>
  )
}

export default ExternalLinkIcon
